var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    class: { hide: _vm.isHidden },
    attrs: {
      id: _vm.id,
      name: _vm.name,
      multiple: _vm.isMultiple,
      type: "file",
      accept: "image/x-png,image/jpeg",
    },
    on: { change: _vm.compressImage },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }